<template>
  <b-card
    v-if="data"
    class="earnings-card chart-title"
    :title="$t('COD Receivable')"
  >
    <b-row>
      <b-col cols="12">
        <!-- chart -->
        <vue-apex-charts
          height="245"
          type="donut"
          :options="earningsChart.chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const $earningsStrokeColor2 = 'rgba(211,10,81,0.4)'
const $earningsStrokeColor3 = 'rgba(104,3,145,0.38)'
const $earningsStrokeColor1 = 'rgba(71,2,101,0.73)'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BCardTitle,
    // BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
          },
          labels: ['Total Receivable COD [Total value of deposits for this warehouse including commissions if any]', 'Agency Receivable Commission [Sum of commissions of deposits of this agency]', 'Branch Receivable COD [Total value of deposits for this branch]'],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -15,
            },
          },
          legend: { show: false },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 200,
                },
                labels: [
                  'Total Receivable COD',
                  'Agency Receivable Commission',
                  'Branch Receivable COD',
                ],
                dataLabels: {
                  style: {
                    fontSize: '10px',
                  },
                },
              },
            },
          ],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $earningsStrokeColor1],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                    show: false,
                  },
                  value: {
                    offsetY: 5,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'COD',
                    formatter() {
                      return '100%'
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  },
}
</script>
