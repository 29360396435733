<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <b-col
        md="4"
        class="align-content-center"
      >
        <ecommerce-statistics :data="tempdata.statisticsItems" />
      </b-col>
      <b-col
        md="8"
        class="align-content-center"
      >
        <ecommerce-financial-statistics :data="tempdata.financialstatisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        md="6"
      >
        <div v-if="!isMonthlyEarningsLoading">
          <ecommerce-monthly-earnings-chart :data="tempdata.monthlyEarnings" />
        </div>
      </b-col>
      <b-col
        md="6"
      >
        <div v-if="!isBarChartLoading">
          <ecommerce-revenue-report :data="tempdata.monthlyOrders" />
        </div>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        md="6"
      >
        <div v-if="!isCODReceivableLoading">
          <ecommerce-earnings-chart :data="tempdata.codReceivable" />
        </div>
      </b-col>
      <b-col
        md="6"
      >
        <div v-if="!isToBeInvoicesLoading">
          <ecommerce-transactions :data="tempdata.toBeInvoices" />
        </div>
      </b-col>

      <!-- Revenue Report Card -->
      <!-- <b-col md="6"> -->
      <!--        <div v-if="!isMerchantOnBoarding">-->
      <!--          <ecommerce-client-onboarded :data="tempdata.monthlyDeliveryCharge" />-->
      <!--        </div>-->
      <!-- </b-col> -->
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <div v-if="!isMStatusStatistics">
          <ecommerce-company-table :table-data="tempdata.companyTable.data" />
        </div>
      </b-col>
      <!--/ Company Table Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <ecommerce-last-few-monthly-earning :data="tempdata.monthlyEarnings" />
      </b-col>
      <!--/ Company Table Card -->
    </b-row>

    <b-row class="match-height">
      <!-- City Wise Delivery Statistics In Selected Warehouse -->
      <b-col
        md="12"
      >
        <div v-if="!isCitywiseStatsLoading">
          <ecommerce-client-onboarded
            title="Citywise Delivery Statistics"
            :data="tempdata.cityWiseStats"
          />
        </div>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import EcommerceStatistics from '@/components/Dashboard/EcommerceStatistics.vue'
import EcommerceTransactions from '@/components/Dashboard/EcommerceTransactions.vue'
import EcommerceFinancialStatistics from '@/components/Dashboard/EcommerceFinancialStatistics.vue'
import EcommerceRevenueReport from '@/components/Dashboard/EcommerceRevenueReport.vue'
import EcommerceEarningsChart from '@/components/Dashboard/EcommerceEarningsChart.vue'
import EcommerceMonthlyEarningsChart from '@/components/Dashboard/EcommerceMonthlyEarning.vue'
import EcommerceCompanyTable from '@/components/Dashboard/EcommerceCompanyTable.vue'
import EcommerceClientOnboarded from '@/components/Dashboard/EcommerceClientOnboarded.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const WarehouseDashboardRepository = RepositoryFactory.get('warehousedashboard')
const BusinessSettingsRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    // EcommerceClientOnboarded,
    BRow,
    BCol,
    EcommerceTransactions,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceFinancialStatistics,
    EcommerceMonthlyEarningsChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceClientOnboarded,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      id: null,
      data: {},
      warehouseDashboard: {
        orderStatsData: {},
        financialStatsData: {},
        monthlyOrdersData: {},
        monthlyEarningsData: {},
        toBeInvoicesData: {},
        codReceivableData: {},
        statusStatisticsData: {},
        cityWiseStatsData: {},
      },
      isBarChartLoading: false,
      isMonthlyEarningsLoading: false,
      isCODReceivableLoading: false,
      isToBeInvoicesLoading: false,
      isMerchantOnBoarding: false,
      isMStatusStatistics: false,
      isCitywiseStatsLoading: false,
      tempdata: {
        statisticsItems: [
          {
            icon: 'BoxIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Orders Count',
          },
          {
            icon: 'DollarSignIcon',
            customClass: 'ml-3',
            color: 'light-success',
            title: 0,
            subtitle: 'Total Delivery Charge',
          },
        ],
        financialstatisticsItems: [
          {
            icon: 'ShoppingBagIcon',
            customClass: '',
            color: 'light-info',
            title: 0,
            subtitle: 'Invoice Value',
          },
          {
            icon: 'LoaderIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Pending Invoice',
          },
          {
            icon: 'CheckCircleIcon',
            customClass: '',
            color: 'light-success',
            title: 0,
            subtitle: 'Paid Invoice Value',
          },
          {
            icon: 'UserCheckIcon',
            customClass: '',
            color: 'light-primary',
            title: 0,
            subtitle: 'Approved Invoice',
          },
        ],
        monthlyEarnings: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivery Charge',
              data: [],
            },
          ],
        },
        codReceivable: {
          series: [],
        },
        toBeInvoices: [
          {
            avatarVariant: 'light-warning',
            avatar: 'CodesandboxIcon',
            mode: 'Collected COD',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-success',
            avatar: 'TruckIcon',
            mode: 'Delivery Charge',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-warning',
            avatar: 'PercentIcon',
            mode: 'Commission',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-info',
            avatar: 'DollarSignIcon',
            mode: 'Invoice Value',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
        ],
        monthlyOrders: {
          categories:
              { lables: [] },
          series: [
            { data: [] },
          ],
        },
        monthlyDeliveryCharge: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivery Expenses',
              data: [],
            },
            {
              name: 'Partially Delivered',
              data: [],
            },
            {
              name: 'Return Income',
              data: [],
            },
          ],
        },
        companyTable: {
          data: [],
        },
        cityWiseStats: {
          categories:
            { lables: [] },
          series: [
            {
              name: 'Delivered orders',
              data: [],
            },
          ],
        },
      },
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getDashboardDetails()
    this.$root.$on('refreshWarehouseDashboardData', () => {
      this.getDashboardDetails()
    })
  },
  mounted() {
    this.getBusinessCurrency()
  },
  async beforeCreate() {
    await this.getBusinessCurrency()
  },
  methods: {
    getDashboardDetails() {
      this.readDataforOrderStats()
      this.readDataforFinancialStats()
      this.readDataforMonthlyOrders()
      this.readDataforMonthlyEarnings()
      this.readDataforCODReceivable()
      this.readDataforToBeInvoices()
      // this.readDataforMerchantOnBoarding()
      this.readDataforStatusStatistics()
      this.readDataforCitywiseStats()
    },
    // currentDate() {
    //   const current = new Date()
    //   return `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    // },
    async readDataforOrderStats() {
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)

        if (warehouseDashboardDataString && warehouseDashboardData.orderStatsData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getOrderStatsFinance(this.id)).data
          this.warehouseDashboard.orderStatsData = data
          const refreshDate = new Date()

          localStorage.setItem(`${this.id}-warehouse-dashboard-refresh-time`, JSON.stringify(refreshDate))
          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        this.tempdata.statisticsItems[0].title = this.warehouseDashboard.orderStatsData.order_count
        this.tempdata.statisticsItems[1].title = this.warehouseDashboard.orderStatsData.total_delivery_charge
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforFinancialStats() {
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)
        if (warehouseDashboardDataString && warehouseDashboardData.financialStatsData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getFinancialStatFinance(this.id)).data
          this.warehouseDashboard.financialStatsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        this.tempdata.financialstatisticsItems[0].title = this.warehouseDashboard.financialStatsData.total_invoice_amount
        this.tempdata.financialstatisticsItems[1].title = this.warehouseDashboard.financialStatsData.status_amount[0].amount
        this.tempdata.financialstatisticsItems[2].title = this.warehouseDashboard.financialStatsData.total_earning[1].amount
        this.tempdata.financialstatisticsItems[3].title = this.warehouseDashboard.financialStatsData.total_earning[2].amount
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforMonthlyOrders() {
      this.isBarChartLoading = true
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)

        if (warehouseDashboardDataString && warehouseDashboardData.monthlyOrdersData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getMonthlyOrdersFinance(this.id)).data
          this.warehouseDashboard.monthlyOrdersData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        this.tempdata.monthlyOrders.categories.lables = this.warehouseDashboard.monthlyOrdersData.map(n => (`${n.month} ${n.year}`))
        this.tempdata.monthlyOrders.series[0].data = this.warehouseDashboard.monthlyOrdersData.map(n => n.delivery_charge)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isBarChartLoading = false
    },
    async readDataforMonthlyEarnings() {
      this.isMonthlyEarningsLoading = true
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)

        if (warehouseDashboardDataString && warehouseDashboardData.monthlyEarningsData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getMonthlyEarningsFinance(this.id)).data
          this.warehouseDashboard.monthlyEarningsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        this.tempdata.monthlyEarnings.categories.lables = this.warehouseDashboard.monthlyEarningsData.map(n => (`${n.month} ${n.year}`))
        this.tempdata.monthlyEarnings.series[0].data = this.warehouseDashboard.monthlyEarningsData.map(n => n.delivery_charge)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isMonthlyEarningsLoading = false
    },
    async readDataforCODReceivable() {
      this.isCODReceivableLoading = true
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)

        if (warehouseDashboardDataString && warehouseDashboardData.codReceivableData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getCODRecivableFinance(this.id)).data
          this.warehouseDashboard.codReceivableData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        this.tempdata.codReceivable.series[0] = this.warehouseDashboard.codReceivableData.total_receivable_cod
        this.tempdata.codReceivable.series[1] = this.warehouseDashboard.codReceivableData.agency_receivable_commission
        this.tempdata.codReceivable.series[2] = this.warehouseDashboard.codReceivableData.branch_receivable_cod
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isCODReceivableLoading = false
    },
    async getBusinessCurrency() {
      try {
        const { data: { company_currency } } = (await BusinessSettingsRepository.getbusinessSettingsResource()).data
        if (localStorage.getItem('businessCurrency') === true) {
          localStorage.removeItem('businessCurrency')
        }
        localStorage.setItem('businessCurrency', company_currency)
        // eslint-disable-next-line camelcase
        this.businessProfileCurrency = company_currency
      } catch (e) {
        this.convertAndNotifyError(e)
        this.businessProfileCurrency = 'LKR'
      }
    },
    async readDataforToBeInvoices() {
      this.isToBeInvoicesLoading = true
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)

        if (warehouseDashboardDataString && warehouseDashboardData.toBeInvoicesData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getToBEInvoicesFinance(this.id)).data
          this.warehouseDashboard.toBeInvoicesData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        if (this.warehouseDashboard.toBeInvoicesData[0].collected_cod === null) {
          this.tempdata.toBeInvoices[0].payment = 0.00
        } else {
          this.tempdata.toBeInvoices[0].payment = this.warehouseDashboard.toBeInvoicesData[0].collected_cod
        }
        if (this.warehouseDashboard.toBeInvoicesData[0].delivery_charge === null) {
          this.tempdata.toBeInvoices[1].payment = 0.00
        } else {
          this.tempdata.toBeInvoices[1].payment = this.warehouseDashboard.toBeInvoicesData[0].delivery_charge
        }
        if (this.warehouseDashboard.toBeInvoicesData[0].commision === null) {
          this.tempdata.toBeInvoices[2].payment = 0.00
        } else {
          this.tempdata.toBeInvoices[2].payment = this.warehouseDashboard.toBeInvoicesData[0].commision
        }
        if (this.warehouseDashboard.toBeInvoicesData[0].to_be_invoice_amount === null) {
          this.tempdata.toBeInvoices[3].payment = 0.00
        } else {
          this.tempdata.toBeInvoices[3].payment = this.warehouseDashboard.toBeInvoicesData[0].to_be_invoice_amount
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isToBeInvoicesLoading = false
    },
    // async readDataforMerchantOnBoarding() {
    //   this.isMerchantOnBoarding = true
    //   try {
    //     const { data } = (await WarehouseDashboardRepository.getMerchantOnBoardingFinance(this.id)).data
    //     this.tempdata.monthlyDeliveryCharge.categories.lables = this.currentDate
    //     this.tempdata.monthlyDeliveryCharge.series[0].data = data.map(n => n.data.delivery)
    //     this.tempdata.monthlyDeliveryCharge.series[1].data = data.map(n => n.data.partially)
    //     this.tempdata.monthlyDeliveryCharge.series[2].data = data.map(n => n.data.return)
    //   } catch (e) {
    //     this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
    //   }
    //   this.isMerchantOnBoarding = false
    // },
    async readDataforStatusStatistics() {
      this.isMStatusStatistics = true
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)

        if (warehouseDashboardDataString && warehouseDashboardData.statusStatisticsData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getStatusStatisticsFinance(this.id)).data
          this.warehouseDashboard.statusStatisticsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        this.tempdata.companyTable.data = this.warehouseDashboard.statusStatisticsData
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isMStatusStatistics = false
    },
    // City Wise Delivery Statistics In Selected Warehouse
    async readDataforCitywiseStats() {
      this.isCitywiseStatsLoading = true
      try {
        const warehouseDashboardDataString = localStorage.getItem(`${this.id}-warehouse-dashboard`)
        const warehouseDashboardData = JSON.parse(warehouseDashboardDataString)

        if (warehouseDashboardDataString && warehouseDashboardData.cityWiseStatsData) {
          this.warehouseDashboard = warehouseDashboardData
        } else {
          const { data } = (await WarehouseDashboardRepository.getCityWiseStats(this.id)).data
          this.warehouseDashboard.cityWiseStatsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem(`${this.id}-warehouse-dashboard`, JSON.stringify(this.warehouseDashboard))
        }
        this.tempdata.cityWiseStats.categories.lables = this.warehouseDashboard.cityWiseStatsData.map(n => (`${n.city_name} (${n.warehouse_name})`))
        this.tempdata.cityWiseStats.series[0].data = this.warehouseDashboard.cityWiseStatsData.map(n => n.count)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isCitywiseStatsLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '../../@core/scss/vue/libs/chart-apex.scss';
</style>
